/* eslint-disable react-hooks/rules-of-hooks */
import { API_URL } from 'config/config';
import { getGenericPage } from 'lib/graphql/getGenericPages';
import { contentGroups } from 'lib/gtm';
import PageComponentMapper from 'modules/componentMapping/pageComponentMapper';
import { getLocale } from 'modules/localesMapper';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { Markets } from '../generated/graphql';
import { doNotIndexPage } from '../lib/utils/helpers';
import { IGlobalMetaData } from '../types/GlobalMetadata';
import Custom404 from './404';
import { INotificationBannerProps } from '../components/NotificationBanner/types';

function GenericPage({
  data,
  globalMetadata,
  market,
  locale,
  defaultLocale,
  rewrites,
  error,
  apiUrl,
  dictionary,
}: {
  // breadcrumbsCMSData: IBreadcrumbTitles;
  data: any;
  globalMetadata: IGlobalMetaData;
  env: string;
  market: Markets;
  locale: string;
  defaultLocale: string;
  recallData: INotificationBannerProps;
  rewrites: Record<string, any>;
  searchOverlayCMSData: any;
  cardCarouselCMSData: any;
  dictionary: any;
  error: boolean;
  apiUrl: string;
}) {
  const page = data.genericPages?.[0] || {};
  if (error) {
    return <Custom404 dictionary={dictionary} />;
  }

  useEffect(() => {
    if (page?.contentGroupingTrackingValue) {
      if (page?.contentGroupingTrackingValues?.length > 0) {
        const tempStr = page?.contentGroupingTrackingValues.replaceAll('_', ' ');
        contentGroups({
          cg1: page?.contentGroupingTrackingValue.replaceAll('_', ' '),
          cg2: tempStr,
        });
      } else {
        contentGroups({
          cg1: page?.contentGroupingTrackingValue.replaceAll('_', ' '),
        });
      }
    }
  }, []);
  const noIndex = doNotIndexPage(page, market);

  // TODO - final data need to impliment
  // const extensionData = extensionComponentData(cardCarouselCMSData, rewrites);
  return (
    <>
      <Head>{noIndex && <meta name="robots" content="noindex,nofollow" />}</Head>
      {page?.showCookieBanner === false && (
        <script type="application/javascript">var UC_UI_SUPPRESS_CMP_DISPLAY=true;</script>
      )}
      <PageComponentMapper
        components={page?.pageComponents}
        copy={dictionary || []}
        rewrites={rewrites}
        apiUrl={apiUrl}
        metadata={page?.metaData}
        globalMetadata={globalMetadata}
      />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'global',
  locales,
  params,
  res,
  defaultLocale,
}) => {
  let error = false;
  const slug =
    params?.slug instanceof Array ? (params?.slug?.join('/') as string) : (params?.slug as string);
  const market = locale; // locale from path is representing market
  const currLocale = getLocale(market);

  let genericPageData;
  try {
    genericPageData = await getGenericPage(market as Markets, currLocale, slug);
  } catch (e) {
    throw new Error(`getGenericPage in slug ${slug} failed. Error: ${e}`);
  }

  const data = genericPageData?.data;

  const rewrites: any[] = [];
  if (!data?.genericPages) {
    error = true;
    res.statusCode = 404;
  }

  return {
    props: {
      locale: currLocale,
      env: process.env.RECIPE_ENV?.toLowerCase() || 'dev',
      data: data || {},
      locales,
      rewrites,
      market,
      recallData: null,
      error,
      apiUrl: API_URL,
      defaultLocale,
    },
  };
};
export default GenericPage;
